/**
 * Content information form component
 *
 * @module ui/page/deployment/content-questions-form
 * @category Pages
 */
import form from "@smartedge/em-fe/ui/component/form-managed";
import select from "@smartedge/em-fe/ui/component/form-managed/field-select";
import textarea from "@smartedge/em-fe/ui/component/form-managed/field-textarea";
import h2 from "@smartedge/em-fe/ui/html/h2";
import button from "@smartedge/em-fe/ui/component/form-managed/button";
import div from "@smartedge/em-fe/ui/html/div";
import {
  contentAmounts,
  contentAmountsFriendly,
  intendedUseCase,
  intendedUseCaseFriendly,
} from "model/constants";
import icon from "@smartedge/em-fe/ui/component/icon";
import { formTypes } from "./constants";

export const CONTENT_QUESTION_FORM_ID = "#content-questions-form";

const submit = (formView, onSubmit) => {
  formView.setFullValidation(true);
  const validation = formView.validate(true);
  if (!validation.valid) {
    return;
  }
  onSubmit({
    ...formView.values,
    type: formView.values?.type
      || formView.state?.formData?.type,
    librarySize: formView.values?.librarySize
      || formView.state?.formData?.librarySize,
  });
};

const contentQuestionsForm = (formView) => {
  const { onSubmit, onBack, formState } = formView.state;
  const values = formState?.[formTypes.QUESTIONS_FORM];

  return form(
    `${CONTENT_QUESTION_FORM_ID}${formView.disabled ? ".disabled" : ""}`,
    formView.bind([
      h2("Content Information"),
      [
        select,
        {
          controls: [
            icon.sharp("chevron-down"),
          ],
          options: Object.values(intendedUseCase).map((key) => ({
            label: intendedUseCaseFriendly.get(key),
            value: key,
          })),
          placeholder: "What type of content do you want created?",
          name: "type",
          value: formView.values?.type
            || formView.state?.formData?.type,
          onChange: (value) => {
            formView.update({
              formData: {
                type: value,
              },
            });
          },
          required: true,
        },
      ],
      [
        select,
        {
          controls: [
            icon.sharp("chevron-down"),
          ],
          options: Object.values(contentAmounts).map((key) => ({
            label: contentAmountsFriendly.get(key),
            value: key,
          })),
          placeholder: "How many pieces of content will you need?",
          name: "librarySize",
          value: formView.values?.librarySize
            || formView.state?.formData?.librarySize,
          onChange: (value) => {
            formView.update({
              formData: {
                librarySize: value,
              },
            });
          },
          required: true,
        },
      ],
      [textarea, { label: "What type of content will you need?", name: "description", required: true }],
      [textarea, { label: "Comments", name: "comments" }],
      div(".footer", formView.bind([
        [button.inverse, {
          label: "Back",
          disabled: formView.disabled,
          onClick: onBack,
        }],
        [button, {
          label: "Submit",
          disabled: formView.disabled,
          onClick: () => submit(formView, onSubmit),
        }],
      ])),
    ], values),
  );
};

export default contentQuestionsForm;
