/**
 * Utility to work with navigation.
 *
 * @module util/navigation
 * @category Utilities
 */
import { getAccessToken, PARAM_NAMES } from "util/params";
import { validateToken } from "api/token";

/**
 * Constant object representing the paths of the application.
 *
 * @enum
 * @property {string} FORM - The path for the form page ("/").
 * @property {string} NOT_FOUND - The path for the 404 page ("/404").
 * @property {string} REVIEW - The path for the review page ("/review").
 */
export const PATHS = Object.freeze({
  FORM: "/",
  NOT_FOUND: "/404",
  REVIEW: "/review",
});

/**
 * Redirects the user to a specified link.
 *
 * @param {string} link - The link to redirect to.
 * @param {boolean} [newWindow=false] - Determines whether to open the link in a new window or not.
 * @returns {void}
 */
export const redirectTo = (link, newWindow = false) => {
  if (newWindow) {
    window.open(link, "_blank");
    return;
  }
  window.location.assign(link);
};

/**
 * Redirects to the form with the specified token.
 *
 * @param {string} token - The token to append to the form URL.
 * @returns {void}
 */
export const redirectToForm = (token) => {
  redirectTo(`${PATHS.FORM}?${PARAM_NAMES.TOKEN}=${token}`);
};

/**
 * Redirects to the review page.
 *
 * @function redirectToReviewPage
 * @returns {void} Does not return a value
 */
export const redirectToReviewPage = () => {
  redirectTo(PATHS.REVIEW);
};

/**
 * Validates an access token and redirects to a specified path if the token is invalid.
 *
 * @returns {Promise<boolean>} - Returns a promise that resolves to true if the token is valid,
 * false otherwise.
 */
export const validateTokenAndRedirectIfBad = async () => {
  const token = getAccessToken();
  const valid = await validateToken(token);
  if (valid) {
    return true;
  }
  redirectTo(PATHS.NOT_FOUND);
  return false;
};
