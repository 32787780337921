/**
 * Common functions for deployment form
 *
 * @module ui/page/deployment/common
 * @category Pages
 */
import { formToStepMapping, formTypes, steps } from "./constants";

/**
 * A Map of the form type and it's view
 *
 * @type {Map<formTypes, null | Object>}
 */
export const formViewMap = new Map([
  [formTypes.CONTACT_FORM, null],
  [formTypes.COMPANY_DETAILS_FORM, null],
  [formTypes.TIER_FORM, null],
  [formTypes.DEPLOYMENT_INFO_FORM, null],
  [formTypes.QUESTIONS_FORM, null],
]);

/**
 * Scrolls to the provided form if using mobile screens
 *
 * @param {formTypes} formType
 */
export const scrollToFormIfMobile = (formType) => {
  try {
    if (window.innerWidth <= 500) {
      const y = formViewMap.get(formType).element.parentElement.getBoundingClientRect().top
        + window.scrollY - 100;
      window.scroll({
        top: y,
        behavior: 'smooth',
      });
    }
  } catch {
    console.warn("Failed to find element for scrolling: ", formType);
  }
};

/**
 * Activates a form by its form type
 *
 * @param view
 * @returns {(function(*): void)|*}
 */
export const formActivator = (view) => (formType) => {
  const stepNumber = steps.get(formToStepMapping.get(formType));
  formViewMap.forEach((formView, key) => {
    if (key === formType) {
      formView?.enable?.();
    } else {
      formView?.disable?.();
    }
  });
  view.update({
    currentStep: stepNumber,
  });
  scrollToFormIfMobile(formType);
};
