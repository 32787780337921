/**
 * Endpoints related to orders.
 *
 * @module api/order
 * @category Backend API
 * @subcategory Order
 */
import { makeOrderDTO, responseToOrder } from "model/order";
import { post } from "@smartedge/em-fe/api/request";

/**
 * Available endpoint URLs for different types of orders.
 *
 * @namespace endpoints
 * @property ORDERS
 */
const endpoints = Object.freeze({
  ORDERS: "/api/orders",
});

/**
 * Sends a request to create an order and returns the response.
 *
 * @async
 * @param {Order} order - The order object to be created.
 * @returns {Promise<Order>} - The response object containing the new order details.
 */
// eslint-disable-next-line import/prefer-default-export
export const createOrder = async (order) => responseToOrder(
  (await post(
    endpoints.ORDERS,
    null,
    makeOrderDTO(order),
  )).body,
);
