/**
 * A contact us button-styled link
 *
 * @module ui/component/header
 * @category Components
 */
import a from "@smartedge/em-fe/ui/html/a";

const contactButton = () => a("Contact us", "mailto:ilya@smartedge.com", ".primary");

export default contactButton;
