/**
 * Company details form component
 *
 * @module ui/page/deployment/company-details-form
 * @category Pages
 */
import form from "@smartedge/em-fe/ui/component/form-managed";
import h2 from "@smartedge/em-fe/ui/html/h2";
import input from "@smartedge/em-fe/ui/component/form-managed/field-input";
import select from "@smartedge/em-fe/ui/component/form-managed/field-select";
import textarea from "@smartedge/em-fe/ui/component/form-managed/field-textarea";
import button from "@smartedge/em-fe/ui/component/form-managed/button";
import div from "@smartedge/em-fe/ui/html/div";
import {
  companySizes,
  companySizesFriendly,
  companyTypes,
  companyTypesFriendly,
  intendedUseCase,
  intendedUseCaseFriendly,
  specialityTypes,
  specialityTypesFriendly,
} from "model/constants";
import icon from "@smartedge/em-fe/ui/component/icon";
import { formTypes } from "./constants";

export const COMPANY_DETAILS_FORM_ID = "#company-details-form";

const submit = (formView, onSubmit) => {
  formView.setFullValidation(true);
  const validation = formView.validate(true);
  if (!validation.valid) {
    return;
  }
  onSubmit({
    ...formView.values,
    type: formView.values?.type || formView.state?.formData?.type,
    speciality: formView.state?.formData?.speciality,
    size: formView.values?.size || formView.state?.formData?.size,
    intendedUseCase: formView.values?.intendedUseCase
      || formView.state?.formData?.intendedUseCase,
  });
};

const companyDetailsForm = (formView) => {
  const {
    onSubmit, onBack, formState,
  } = formView.state;
  const values = formState?.[formTypes.COMPANY_DETAILS_FORM];

  const isHealthcareSelected = formView.values?.type === companyTypes.HEALTHCARE
    || formView.state?.formData?.type === companyTypes.HEALTHCARE;
  return form(`${COMPANY_DETAILS_FORM_ID}${formView.disabled ? ".disabled" : ""}`, formView.bind([
    h2("Company Details"),
    [input, { label: "Company Name", name: "name", required: true }],
    [
      select,
      {
        options: Object.values(companyTypes).map((key) => ({
          label: companyTypesFriendly.get(key),
          value: key,
        })),
        placeholder: "Company Type",
        name: "type",
        value: formView.values?.type || formView.state?.formData?.type,
        onChange: (value) => {
          formView.update({
            formData: {
              type: value,
              speciality: value === companyTypes.HEALTHCARE
                ? (formView.values?.speciality || formView.state?.formData?.speciality)
                : undefined,
            },
          });
        },
        controls: [
          icon.sharp("chevron-down"),
        ],
        required: true,
      },
    ],
    isHealthcareSelected
      ? [
        select,
        {
          controls: [
            icon.sharp("chevron-down"),
          ],
          options: Object.values(specialityTypes).map((key) => ({
            label: specialityTypesFriendly.get(key),
            value: key,
          })),
          placeholder: "Speciality",
          name: "speciality",
          value: formView.state?.formData?.speciality || values?.speciality,
          onChange: (value) => {
            formView.update({
              formData: {
                speciality: value,
              },
            });
          },
          required: true,
        },
      ]
      : "",
    [
      select,
      {
        controls: [
          icon.sharp("chevron-down"),
        ],
        options: Object.values(companySizes).map((key) => ({
          label: companySizesFriendly.get(key),
          value: key,
        })),
        placeholder: "Company Size",
        name: "size",
        value: formView.values?.size || formView.state?.formData?.size,
        onChange: (value) => {
          formView.update({
            formData: {
              size: value,
            },
          });
        },
        required: true,
      },
    ],
    [textarea, { label: "Company Goals", name: "goals", required: true }],
    [
      select,
      {
        controls: [
          icon.sharp("chevron-down"),
        ],
        options: Object.values(intendedUseCase).map((key) => ({
          label: intendedUseCaseFriendly.get(key),
          value: key,
        })),
        placeholder: "SmartEdge System Use",
        name: "intendedUseCase",
        value: formView.values?.intendedUseCase
          || formView.state?.formData?.intendedUseCase,
        onChange: (value) => {
          formView.update({
            formData: {
              intendedUseCase: value,
            },
          });
        },
        required: true,
      },
    ],
    div(".footer", formView.bind([
      [
        button.inverse,
        {
          label: "Back",
          disabled: formView.disabled,
          onClick: onBack,
        },
      ],
      [
        button,
        {
          label: "Next",
          disabled: formView.disabled,
          onClick: () => submit(formView, onSubmit),
        },
      ],
    ])),
  ], values));
};

export default companyDetailsForm;
