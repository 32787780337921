import standardLayout from "ui/page/layout/standard";
import div from "@smartedge/em-fe/ui/html/div";
import view from "@smartedge/em-fe/ui/view";
import contactButton from "ui/component/contact-button";
import h1 from "@smartedge/em-fe/ui/html/h1";
import img from "@smartedge/em-fe/ui/html/img";
import a from "@smartedge/em-fe/ui/html/a";
import p from "@smartedge/em-fe/ui/html/p";

const reviewBody = () => div(".review-body", [
  img(".image", "/media/party.svg"),
  h1("THANK YOU FOR SIGNING UP WITH SMARTEDGE!"),
  p("Please be on the lookout for an email from our customer support team."),
  p("We will reach out within 24 hours."),
  p([
    "If you have any questions, please get in touch with ",
    a("sales@smartedge.com", "mailto:sales@smartedge.com"),
    ".",
  ]),
  p("Looking forward to getting started on your SmartEdge journey!"),
]);

export default function review(selector) {
  const title = "Thank You!";
  const { header, loading } = standardLayout(
    selector,
    [
      div("#body"),
    ],
    title,
  );
  header.update({ title, controls: () => [contactButton()] });
  loading.show();

  view.create(reviewBody)("#body");

  loading.hide();
}
