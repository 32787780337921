/**
 * Deployment information form component
 *
 * @module ui/page/deployment/deployment-info-form
 * @category Pages
 */
import form from "@smartedge/em-fe/ui/component/form-managed";
import checkbox from "@smartedge/em-fe/ui/component/form-managed/field-checkbox";
import input from "@smartedge/em-fe/ui/component/form-managed/field-input";
import multiToggle from "@smartedge/em-fe/ui/component/form-managed/field-multi-toggle";
import h2 from "@smartedge/em-fe/ui/html/h2";
import button from "@smartedge/em-fe/ui/component/form-managed/button";
import div from "@smartedge/em-fe/ui/html/div";
import { domainTypes, domainTypesFriendly } from "model/constants";
import icon from "@smartedge/em-fe/ui/component/icon";
import { getModal } from "@smartedge/em-fe/ui/view/modal-view";
import domainHelpModal from "ui/component/modal/help/domain-help";
import { formTypes } from "./constants";

export const DEPLOYMENT_INFO_FORM_ID = "#deployment-info-form";

const openDomainHelpModal = () => {
  getModal().async(domainHelpModal());
};

const submit = (formView, onSubmit) => {
  formView.setFullValidation(true);
  const validation = formView.validate(true);
  if (!validation.valid) {
    return;
  }
  onSubmit({
    ...formView.values,
    domainType: formView.values?.domainType || formView.state?.formData?.domainType,
  });
};

const getMultiToggleValue = (formView, values) => formView?.values?.domainType
  || values?.domainType || formView?.state?.formData?.domainType || domainTypes.CUSTOM;

const deploymentInfoForm = (formView) => {
  const { onSubmit, onBack, formState } = formView.state;
  const values = formState?.[formTypes.DEPLOYMENT_INFO_FORM];

  return form(`${DEPLOYMENT_INFO_FORM_ID}${formView.disabled ? ".disabled" : ""}`, formView.bind([
    h2("Deployment Information"),
    [input, { label: "Deployment Name", name: "name", required: true }],
    [checkbox, {
      label: "Do you have your own website?",
      name: "existingWebsite",
    },
    ],
    formView.values?.existingWebsite
      ? [input, {
        label: "If so, please give us the domain name", name: "existingUrl", required: true, value: values?.existingUrl,
      }]
      : "",
    div(".help-container", formView.bind([
      [multiToggle, {
        label: "Domain Options",
        name: "domainType",
        value: getMultiToggleValue(formView, values),
        onSelect: (option) => {
          formView.update({
            formData: {
              domainType: option.value,
            },
          });
        },
        options: [
          { label: domainTypesFriendly.get(domainTypes.SMARTEDGE), value: domainTypes.SMARTEDGE },
          { label: domainTypesFriendly.get(domainTypes.CUSTOM), value: domainTypes.CUSTOM },
        ],
      }],
      icon("question-square", ".help-toggle", {
        on: {
          click: openDomainHelpModal,
        },
      }),
    ])),
    [input, { label: "Domain Name", name: "deploymentUrl", required: true }],
    div(".footer", formView.bind([
      [button.inverse, {
        label: "Back",
        disabled: formView.disabled,
        onClick: onBack,
      }],
      [button, {
        label: "Next",
        disabled: formView.disabled,
        onClick: () => submit(formView, onSubmit),
      }],
    ])),
  ], values));
};

export default deploymentInfoForm;
