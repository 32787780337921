/**
 * Common UI layout component.
 *
 * @module ui/page/layout/standard
 * @category Layout
 */
import div from "@smartedge/em-fe/ui/html/div";
import header from "@smartedge/em-fe/ui/html/header";
import el, { patch } from "@smartedge/em-fe/ui/common/el";
import view from "@smartedge/em-fe/ui/view";
import { setTitle } from "@smartedge/em-fe/util/navigation";
import { standardHeader } from "ui/component/header";

export default function standardLayout(selector, elements = [], title = "") {
  const structure = div(`${selector}.page`, [
    header(),
    ...elements,
    div("#modal"),
    div("#loading"),
    div("#notifications"),
  ]);

  const container = el.fromDOM(document.querySelector(selector));

  patch(container, structure);

  const loadingView = view.loading("#loading");
  view.modal(() => "")("#modal", {});
  view.notification("#notifications", {});

  setTitle(title);

  const headerView = view.create(standardHeader)("header");

  return {
    loading: loadingView,
    header: headerView,
  };
}
