/**
 * Constants for the automation form
 *
 * @module ui/page/deployment/constants
 * @category Pages
 */

/**
 * Automation page steps definition
 *
 * @property GENERAL_INFORMATION
 * @property DEPLOYMENT_INFORMATION
 * @property QUESTIONS
 */
export const deploymentFormSteps = Object.freeze({
  GENERAL_INFORMATION: "GENERAL_INFORMATION",
  DEPLOYMENT_INFORMATION: "DEPLOYMENT_INFORMATION",
  QUESTIONS: "QUESTIONS",
});

/**
 * Page step definition to step number mapping
 *
 * @type {Readonly<Map<deploymentFormSteps, number>>}
 */
export const steps = Object.freeze(new Map([
  [deploymentFormSteps.GENERAL_INFORMATION, 0],
  [deploymentFormSteps.DEPLOYMENT_INFORMATION, 1],
  [deploymentFormSteps.QUESTIONS, 2],
]));

/**
 * Form definitions
 *
 * @property CONTACT_FORM
 * @property COMPANY_DETAILS_FORM
 * @property TIER_FORM
 * @property DEPLOYMENT_INFO_FORM
 * @property QUESTIONS_FORM
 */
export const formTypes = Object.freeze({
  CONTACT_FORM: "CONTACT_FORM",
  COMPANY_DETAILS_FORM: "COMPANY_DETAILS_FORM",
  TIER_FORM: "TIER_FORM",
  DEPLOYMENT_INFO_FORM: "DEPLOYMENT_INFO_FORM",
  QUESTIONS_FORM: "QUESTIONS_FORM",
});

/**
 * Form type to deployment page step mapping
 *
 * @type {Readonly<Map<formTypes, deploymentFormSteps>>}
 */
export const formToStepMapping = Object.freeze(new Map([
  [formTypes.CONTACT_FORM, deploymentFormSteps.GENERAL_INFORMATION],
  [formTypes.COMPANY_DETAILS_FORM, deploymentFormSteps.GENERAL_INFORMATION],
  [formTypes.TIER_FORM, deploymentFormSteps.DEPLOYMENT_INFORMATION],
  [formTypes.DEPLOYMENT_INFO_FORM, deploymentFormSteps.DEPLOYMENT_INFORMATION],
  [formTypes.QUESTIONS_FORM, deploymentFormSteps.QUESTIONS],
]));
