/**
 * Customer models.
 *
 * @module model/customer
 * @category Model
 * @subcategory Customer
 */
import { orNull, validMember, validString } from "@smartedge/em-fe/model/constraints";
import {
  companySizesSet,
  companyTypesSet,
  intendedUseCaseSet,
  specialityTypesSet,
} from "model/constants";

/**
 * @typedef Customer
 *
 * @property {Company} company
 * @property {Contact} contact
 * @property {intendedUseCase} intendedUseCase
 */

/**
 * @typedef Company
 *
 * @property {string} name
 * @property {companyTypes} type
 * @property {specialityTypes} speciality
 * @property {companySizes} size
 * @property {string} goals
 */

/**
 * @typedef Contact
 *
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} cellPhone
 * @property {string} email
 * @property {string?} role
 * @property {string} street
 * @property {string} town
 * @property {string} state
 * @property {string} zipCode
 * @property {string} country
 */

/**
 * Convert contact response to model
 *
 * @param {string} response.firstName
 * @param {string} response.lastName
 * @param {string} response.phone
 * @param {string} response.email
 * @param {string} response.role
 * @param {string} response.address.street
 * @param {string} response.address.town
 * @param {string} response.address.state
 * @param {string} response.address.zipCode
 * @param {string} response.address.country
 * @returns Contact
 */
export const responseToContact = (response) => {
  const { address } = response;

  return {
    firstName: response.firstName,
    lastName: response.lastName,
    cellPhone: response.phone,
    email: response.email,
    role: response.role,
    street: address?.street,
    town: address?.town,
    state: address?.state,
    zipCode: address?.zipCode,
    country: address?.country,
  };
};

/**
 * Convert contact response to model
 *
 * @param {string} response.companyName
 * @param {string} response.industry
 * @param {string} response.field
 * @param {string} response.companySize
 * @param {string} response.companyGoals
 * @param {string} response.intendedSmartEdgeUseCase
 * @returns Company
 */
export const responseToCompany = (response) => ({
  name: response?.companyName,
  type: response?.industry,
  speciality: response?.field,
  size: response?.companySize,
  goals: response?.companyGoals,
  intendedUseCase: response.intendedSmartEdgeUseCase,
});

/**
 * Convert customer response to model
 *
 * @param {object} response.companyInfo
 * @param {object} response.contact
 * @returns Customer
 */
export const responseToCustomer = (response) => ({
  company: responseToCompany(response.companyInfo),
  contact: responseToContact(response.contact),
});

/**
 * @typedef CustomerDTO
 *
 * @property {object} companyInfo
 * @property {string} companyInfo.companyName
 * @property {companyTypes} companyInfo.industry
 * @property {specialityTypes} companyInfo.field
 * @property {companySizes} companyInfo.companySize
 * @property {string} companyInfo.companyGoals
 * @property {string} companyInfo.intendedSmartEdgeUseCase
 *
 * @property {object} contact
 * @property {string} contact.firstName
 * @property {string} contact.lastName
 * @property {string} contact.phone
 * @property {string} contact.email
 * @property {string} contact.role
 * @property {object} contact.address
 * @property {string} contact.address.street
 * @property {string} contact.address.town
 * @property {string} contact.address.state
 * @property {string} contact.address.zipCode
 * @property {string} contact.address.country
 */

/**
 * Converts {@link Customer} to {@link CustomerDTO}
 *
 * @param {Customer} partial
 * @returns CustomerDTO
 */
export const makeCustomerDTO = (partial) => ({
  companyInfo: {
    companyName: validString(partial.company.name, "Customer.company.name"),
    industry: validMember(
      partial.company.type,
      "Customer.company.type",
      companyTypesSet,
    ),
    field: orNull(
      partial.company.speciality,
      "Customer.company.speciality",
      () => validMember(
        partial.company.speciality,
        "Customer.company.speciality",
        specialityTypesSet,
      ),
    ),
    companySize: validMember(
      partial.company.size,
      "Customer.company.size",
      companySizesSet,
    ),
    companyGoals: validString(partial.company.goals, "Customer.company.goals"),
    intendedSmartEdgeUseCase: validMember(
      partial.company.intendedUseCase,
      "Customer.company.intendedUseCase",
      intendedUseCaseSet,
    ),
  },
  contact: {
    firstName: validString(partial.contact.firstName, "Customer.contact.firstName"),
    lastName: validString(partial.contact.lastName, "Customer.contact.lastName"),
    phone: validString(partial.contact.cellPhone, "Customer.contact.cellPhone"),
    email: validString(partial.contact.email, "Customer.contact.email"),
    role: validString(partial.contact.role, "Customer.contact.role"),
    address: {
      street: validString(partial.contact.street, "Customer.contact.street"),
      town: validString(partial.contact.town, "Customer.contact.town"),
      state: validString(partial.contact.state, "Customer.contact.state"),
      zipCode: validString(partial.contact.zipCode, "Customer.contact.zipCode"),
      country: validString(partial.contact.country, "Customer.contact.country"),
    },
  },
});
