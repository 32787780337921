/**
 * A stepper component. Steps transitions are controlled outside of a stepper
 * by currentStep property
 *
 * @module ui/component/stepper
 * @category Components
 */
import div from "@smartedge/em-fe/ui/html/div";
import { merge } from "@smartedge/em-fe/util/object";

/**
 * @typedef Step
 * @property {object} layout
 */

/**
 * @type {{
 *  currentStep: number,
 *  steps: Step[]
 * }}
 */
const defaultState = {
  steps: [],
  currentStep: 0,
};

const isStepActive = (index, currentStep) => index === currentStep;

/**
 * @param {Step} step
 * @param {number} index
 * @param {defaultState} state
 * @returns {El|*}
 */
const stepLayout = (step, index, state) => {
  const {
    currentStep,
  } = state;
  return div(
    `.step${isStepActive(index, currentStep) ? ".active" : ""}`,
    {
      attrs: {
        index,
      },
    },
    [
      div(".body", step.layout),
    ],
  );
};

export default function stepper(inState = defaultState) {
  const state = merge(defaultState, inState);
  const { steps, currentStep } = state;

  return div(
    ".stepper-container",
    div(
      ".stepper",
      {
        style: {
          transform: `translateX(-${100 * currentStep}%)`,
        },
      },
      steps.map((step, index) => stepLayout(step, index, state)),
    ),
  );
}
