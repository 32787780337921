/**
 * Tier form component
 *
 * @module ui/page/deployment/tier-form
 * @category Pages
 */
import form from "@smartedge/em-fe/ui/component/form-managed";
import checkbox from "@smartedge/em-fe/ui/component/form-managed/field-checkbox";
import textarea from "@smartedge/em-fe/ui/component/form-managed/field-textarea";
import radio from "@smartedge/em-fe/ui/component/form-managed/field-radio";
import h2 from "@smartedge/em-fe/ui/html/h2";
import button from "@smartedge/em-fe/ui/component/form-managed/button";
import div from "@smartedge/em-fe/ui/html/div";
import span from "@smartedge/em-fe/ui/html/span";
import { tierTypes, tierTypesFriendly } from "model/constants";
import { formTypes } from "./constants";

export const TIER_FORM_ID = "#tier-form";

const submit = (formView, onSubmit) => {
  formView.setFullValidation(true);
  const validation = formView.validate(true);
  if (!validation.valid) {
    return;
  }
  onSubmit({
    ...formView.values,
    tier: formView.values?.tier || formView.state?.formData?.tier,
  });
};

const tierForm = (formView) => {
  const { onSubmit, onBack, formState } = formView.state;
  const values = formState?.[formTypes.TIER_FORM];

  return form(`${TIER_FORM_ID}${formView.disabled ? ".disabled" : ""}`, formView.bind([
    h2("System"),
    [textarea, { label: "How often do you anticipate using this system?", name: "useDuration", required: true }],
    [textarea, {
      label: "How many users do you think will use your site on a weekly basis?",
      name: "weeklyUsers",
      required: true,
    }],
    [radio.contentBox, {
      entries: [
        {
          label: div(".entry-content", [
            span(tierTypesFriendly.get(tierTypes.TIER_1), ".title"),
            div(".subtitle-container", [
              span("Open Site Access", ".subtitle"),
              span("Site Customization", ".subtitle"),
              span("Unlimited Pages", ".subtitle"),
              span("25GB Content Library", ".subtitle"),
              span("One Administrator", ".subtitle"),
            ]),
          ]),
          value: tierTypes.TIER_1,
        },
        {
          label: div(".entry-content.disabled", [
            span(tierTypesFriendly.get(tierTypes.TIER_2), ".title"),
            div(".subtitle-container", [
              span("Open or Hybrid Site Access", ".subtitle"),
              span("Site Customization", ".subtitle"),
              span("Custom Domain", ".subtitle"),
              span("Unlimited Pages", ".subtitle"),
              span("75GB Content Library", ".subtitle"),
              span("Up to Ten Administrators", ".subtitle"),
              span("Unlimited Registered Users", ".subtitle"),
            ]),
          ]),
          value: tierTypes.TIER_2,
          sel: ".disabled",
        },
        {
          label: div(".entry-content.disabled", [
            span(tierTypesFriendly.get(tierTypes.TIER_3), ".title"),
            div(".subtitle-container", [
              span("Please get in touch for more details", ".subtitle"),
            ]),
          ]),
          value: tierTypes.TIER_3,
          sel: ".disabled",
        },
      ],
      required: true,
      name: "tier",
    }],
    [checkbox, { label: "More Storage", name: "moreStorage" }],
    div(".footer", formView.bind([
      [button.inverse, {
        label: "Back",
        disabled: formView.disabled,
        onClick: onBack,
      }],
      [button, {
        label: "Next",
        disabled: formView.disabled,
        onClick: () => submit(formView, onSubmit),
      }],
    ])),
  ], values));
};

export default tierForm;
