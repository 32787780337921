/**
 * Common pages functions
 *
 * @module ui/common
 * @category Common
 */
import page from "ui/page";

/**
 * Executes the given page module function with the provided selector and resolves
 * the promise with true if successful, otherwise rejects with an error.
 *
 * @param {string} pageModule - The name of the page module function to execute.
 * @param {string} selector - The selector used by the page module function.
 * @param {function} resolve - The function to call when the execution is successful.
 * @param {function} reject - The function to call when an error occurs during execution.
 *
 * @returns {Promise} - A promise that resolves with true if successful or rejects with an error.
 */
const onReady = async (pageModule, selector, resolve, reject) => {
  try {
    await page[pageModule](selector);
    resolve(true);
  } catch (e) {
    reject(e);
  }
};

/**
 * Initializes a page by waiting for the "sp:ready" event and then executing the provided callback.
 *
 * @param {string} pageModule - The name of the page module function to execute.
 * @param {string} selector - The CSS selector of the element to apply the callback on.
 *
 * @returns {Promise} A Promise that resolves when the page is ready and the
 * callback has been executed.
 */
// eslint-disable-next-line import/prefer-default-export
export const initPage = (pageModule, selector) => new Promise((resolve, reject) => {
  window.addEventListener(
    "sp:ready",
    () => {
      onReady(pageModule, selector, resolve, reject)
        .catch((e) => console.error(e, "in sp:ready handler"));
    },
    { once: true },
  );
});
