/**
 * Contact information form component
 *
 * @module ui/page/deployment/contact-form
 * @category Pages
 */
import form from "@smartedge/em-fe/ui/component/form-managed";
import h2 from "@smartedge/em-fe/ui/html/h2";
import firstName from "@smartedge/em-fe/ui/component/form-managed/field-given-name";
import lastName from "@smartedge/em-fe/ui/component/form-managed/field-family-name";
import input from "@smartedge/em-fe/ui/component/form-managed/field-input";
import phone from "@smartedge/em-fe/ui/component/form-managed/field-phone-number";
import email from "@smartedge/em-fe/ui/component/form-managed/field-email-address";
import button from "@smartedge/em-fe/ui/component/form-managed/button";
import div from "@smartedge/em-fe/ui/html/div";
import { formTypes } from "./constants";

export const CONTACT_FORM_ID = "#contact-form";

const submit = (formView, onSubmit) => {
  formView.setFullValidation(true);
  const validation = formView.validate(true);
  if (!validation.valid) {
    return;
  }
  onSubmit(formView.values);
};

const contactForm = (formView) => {
  const { onSubmit, formState } = formView.state;
  const values = formState?.[formTypes.CONTACT_FORM];

  return form(`${CONTACT_FORM_ID}${formView.disabled ? ".disabled" : ""}`, formView.bind([
    h2("Customer Information"),
    [firstName, { required: true }],
    [lastName, { required: true }],
    [input, { label: "Role", name: "role", required: true }],
    [input, { label: "Street", name: "street", required: true }],
    [input, { label: "Town / City", name: "town", required: true }],
    [input, { label: "State / Province", name: "state", required: true }],
    [input, { label: "Zip Code", name: "zipCode", required: true }],
    [input, { label: "Country", name: "country", required: true }],
    [phone, { required: true }],
    [email, { required: true }],
    div(".footer", formView.bind([
      [button, {
        label: "Next",
        disabled: formView.disabled,
        onClick: () => submit(formView, onSubmit),
      }],
    ])),
  ], values));
};

export default contactForm;
