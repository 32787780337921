/**
 * Invite link handler. Validates only that the token is present in query
 * params
 *
 * @module ui/page/invite
 * @category Pages
 */
import standardLayout from "ui/page/layout/standard";
import { PATHS, redirectTo, redirectToForm } from "util/navigation";
import { getAccessToken } from "util/params";

export default async function invite(selector) {
  const { loading } = standardLayout(
    selector,
    [],
  );
  loading.show();

  const token = getAccessToken();
  if (token) {
    redirectToForm(token);
  } else {
    redirectTo(PATHS.NOT_FOUND);
  }

  loading.hide();
}
