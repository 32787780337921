/**
 * A domain selection help modal dialog.
 *
 * @module ui/component/modal/help/domain-help
 * @category UI
 * @subcategory Modal Dialogs
 */
import commonHelpModal from "@smartedge/em-fe/ui/component/modal/help/common-help";
import p from "@smartedge/em-fe/ui/html/p";
import a from "@smartedge/em-fe/ui/html/a";

export default function domainHelpModal() {
  return commonHelpModal({
    modalType: "DOMAIN_HELP",
    skipCache: true,
    title: "HELP",
    sel: ".domain-help-dialog",
  }, [
    p("SmartEdge Domain allows you to host your deployment directly on SmartEdge. The domain will be [your-deployment-name].smartedge.com."),
    p("Custom Domain allows you to host your deployment on your own domain. The domain will be [your-deployment-domain.com or smartedge-deployment.your-deployment-domain.com]. If you decide to connect your domain with SmartEdge, this will require the added step of creating/connecting a CNAME record with your DNS Hosting Service (GoDaddy, NameCheap, etc)."),
    p([
      "For further assistance, please contact ",
      a("support@smartedge.com", "mailto:support@smartedge.com"),
      ".",
    ]),
  ]);
}
