/**
 * Utility to work with URL params.
 *
 * @module util/cache
 * @category Utilities
 */

/**
 * Represents a constant object containing parameter names.
 *
 * @type {Object}
 * @property {string} TOKEN - The name of the access token parameter.
 */
export const PARAM_NAMES = Object.freeze({
  TOKEN: "token",
});

/**
 * Retrieves the query parameters from the current URL.
 *
 * @returns {URLSearchParams} - An instance of URLSearchParams containing the query parameters.
 */
export const getQueryParams = () => new URLSearchParams(window.location.search);

/**
 * Retrieves the access token from the query parameters.
 *
 * @returns {string|null} The access token or null if not found.
 */
export const getAccessToken = () => getQueryParams().get(PARAM_NAMES.TOKEN);
