import ui from "ui";
import { emit } from "@smartedge/em-fe/util/event";

window.smartedge = {
  ui,
};

/**
 * Executes the specified code when the content of the page has finished loading.
 * Emits a "sp:ready" event on the window object.
 *
 * @async
 * @function onContentLoad
 */
const onContentLoad = async () => emit(window, "sp:ready");

document.addEventListener(
  "DOMContentLoaded",
  () => { onContentLoad().catch((e) => { console.error(e); }); },
);
