/**
 * Endpoints related to tokens.
 *
 * @module api/token
 * @category Backend API
 * @subcategory Token
 */
import { get } from "@smartedge/em-fe/api/request";

/**
 * The endpoints object contains the API endpoint URLs for various operations.
 *
 * @namespace endpoints
 * @property VALIDATE_TOKEN
 */
const endpoints = Object.freeze({
  VALIDATE_TOKEN: "/api/invite-tokens/validate",
});

/**
 * Validates a token by calling an API endpoint.
 *
 * @async
 * @param {string} token - The token to validate.
 * @returns {Promise<boolean>} - True if the token is valid, false otherwise.
 */
// eslint-disable-next-line import/prefer-default-export
export const validateToken = async (token) => {
  try {
    return (await get(`${endpoints.VALIDATE_TOKEN}/${token}`)).ok;
  } catch (e) {
    return false;
  }
};
