/**
 * A cache util
 *
 * @module util/cache
 * @category Utilities
 */
import { getAccessToken } from "util/params";

/**
 * The prefix used for cache keys in the SmartEdgeAutomator application.
 *
 * @type {string}
 */
const CACHE_KEY_PREFIX = "SmartEdgeAutomator";

/**
 * Generates a storage key by prepending a prefix to the provided key.
 *
 * @param {string} key - The key to generate the storage key for.
 * @returns {string} The generated storage key.
 */
const makeStorageKey = (key) => `${CACHE_KEY_PREFIX}_${key}`;

/**
 * Retrieves an item from local storage with the specified key.
 *
 * @param {string} key - The key of the item.
 * @returns {any} - The retrieved item.
 */
const getItem = (key) => JSON.parse(localStorage.getItem(makeStorageKey(key)));

/**
 * Sets the value for a specified key in the localStorage.
 *
 * @param {string} key - The key to store the value under.
 * @param {any} value - The value to be stored.
 * @returns {void}
 */
const setItem = (key, value) => localStorage.setItem(
  makeStorageKey(key),
  JSON.stringify(value),
);

/**
 * Clears the contents of the localStorage.
 *
 * @function clearStorage
 * @returns {void} - Returns nothing.
 */
const clearStorage = () => localStorage.clear();

/**
 * Retrieves the current state of the form.
 *
 * @returns {object} The current state of the form.
 */
export const getCurrentFormState = () => getItem(getAccessToken());

/**
 * Saves the form state to local storage.
 *
 * @param {string} formType - The type of the form.
 * @param {string} activeFormType - The type of the active form.
 * @param {object} values - The form values.
 *
 * @returns {void}
 */
export const saveFormState = (formType, activeFormType, values) => {
  const accessToken = getAccessToken();
  const currentState = getCurrentFormState();
  setItem(accessToken, {
    ...currentState,
    activeForm: activeFormType,
    [formType]: values,
  });
};

/**
 * Clears the form state by clearing the storage.
 *
 * @function clearFormState
 * @returns {void}
 */
export const clearFormState = () => {
  clearStorage();
};
