/**
 * Order models.
 *
 * @module model/order
 * @category Model
 * @subcategory Order
 */
import { makeCustomerDTO, responseToCustomer } from "model/customer";
import {
  orNull, validBoolean, validMember, validString,
} from "@smartedge/em-fe/model/constraints";
import {
  contentAmountsSet, domainTypesSet, intendedUseCaseSet, tierTypesSet,
} from "model/constants";

/**
 * @typedef System
 *
 * @property {string} useDuration
 * @property {string} weeklyUsers
 * @property {tierTypes} tier
 * @property {boolean} moreStorage
 */

/**
 * @typedef Deployment
 *
 * @property {string} name
 * @property {boolean} existingWebsite
 * @property {string} existingUrl
 * @property {domainTypes} domainType
 * @property {string} deploymentUrl
 */

/**
 * @typedef Content
 *
 * @property {string} type
 * @property {string} librarySize
 * @property {string?} description
 * @property {string?} comments
 */

/**
 * @typedef Order
 *
 * @property {Customer} customer
 * @property {System} system
 * @property {Deployment} deployment
 * @property {Content} content
 * @property {string} inviteToken
 */

/**
 * Converts system response to {@link System}
 *
 * @param {object} response
 * @returns System
 */
export const responseToSystem = (response) => ({
  useDuration: response.systemUseDuration,
  weeklyUsers: response.weeklyUsers,
  tier: response.tier,
  moreStorage: response.moreStorage,
});

/**
 * Converts deployment info response to {@link Deployment}
 *
 * @param {object} response
 * @returns Deployment
 */
export const responseToDeploymentInfo = (response) => ({
  name: response.deploymentName,
  existingWebsite: response.existingWebsite,
  existingUrl: response.existingUrl,
  domainType: response.domainType,
  deploymentUrl: response.deploymentUrl,
});

/**
 * Converts content info response to {@link Content}
 *
 * @param {object} response
 * @returns Content
 */
export const responseToContentInfo = (response) => ({
  type: response.contentType,
  librarySize: response.contentLibrarySize,
  description: response.contentTypeDescription,
  comments: response.comments,
});

/**
 * Converts {@link OrderDTO} to {@link Order}
 *
 * @param {object | string} response.customer (may be uuid)
 * @param {object} response.systemInfo
 * @param {object} response.deploymentInfo
 * @param {object} response.contentInfo
 * @param {string} response.inviteTokenId
 * @returns Order
 */
export const responseToOrder = (response) => ({
  customer: (typeof response.customer === "string")
    ? response.customer
    : responseToCustomer(response.customer),
  system: responseToSystem(response.systemInfo),
  deployment: responseToDeploymentInfo(response.deploymentInfo),
  content: responseToContentInfo(response.contentInfo),
  inviteToken: response.inviteTokenId,
});

/**
 * @typedef OrderDTO
 *
 * @property {CustomerDTO} customer
 *
 * @property {object} systemInfo
 * @property {string} systemInfo.systemUseDuration
 * @property {string} systemInfo.weeklyUsers
 * @property {tierTypes} systemInfo.tier
 * @property {boolean} systemInfo.moreStorage
 *
 * @property {object} deploymentInfo
 * @property {string} deploymentInfo.deploymentName
 * @property {boolean} deploymentInfo.existingWebsite
 * @property {string?} deploymentInfo.existingUrl
 * @property {domainTypes} deploymentInfo.domainType
 * @property {string} deploymentInfo.deploymentUrl
 *
 * @property {object} contentInfo
 * @property {string} contentInfo.contentType
 * @property {string} contentInfo.contentLibrarySize
 * @property {string?} contentInfo.contentTypeDescription
 * @property {string?} contentInfo.comments
 *
 * @property {string} inviteTokenId
 */

/**
 * Converts {@link Order} to {@link OrderDTO}
 *
 * @param {Order} partial
 * @returns OrderDTO
 */
export const makeOrderDTO = (partial) => ({
  customer: makeCustomerDTO(partial.customer),
  systemInfo: {
    systemUseDuration: validString(partial.system.useDuration, "Order.system.useDuration"),
    weeklyUsers: validString(partial.system.weeklyUsers, "Order.system.weeklyUsers"),
    tier: validMember(
      partial.system.tier,
      "Order.system.tier",
      tierTypesSet,
    ),
    moreStorage: validBoolean(partial.system.moreStorage, "Order.system.moreStorage"),
  },
  deploymentInfo: {
    deploymentName: validString(partial.deployment.name, "Order.deployment.name"),
    existingWebsite: validBoolean(partial.deployment.existingWebsite, "Order.deployment.existingWebsite"),
    existingUrl: orNull(
      partial.deployment.existingUrl,
      "Order.deployment.existingUrl",
      validString,
    ),
    domainType: validMember(
      partial.deployment.domainType,
      "Order.deployment.domainType",
      domainTypesSet,
    ),
    deploymentUrl: validString(partial.deployment.deploymentUrl, "Order.deployment.deploymentUrl"),
  },
  contentInfo: {
    contentType: validMember(
      partial.content.type,
      "Order.content.type",
      intendedUseCaseSet,
    ),
    contentLibrarySize: validMember(
      partial.content.librarySize,
      "Order.content.librarySize",
      contentAmountsSet,
    ),
    contentTypeDescription: orNull(
      partial.content.description,
      "Order.content.description",
      validString,
    ),
    comments: orNull(
      partial.content.comments,
      "Order.content.comments",
      validString,
    ),
  },
  inviteTokenId: validString(partial.inviteToken, "Order.inviteToken"),
});
