/**
 * A header component
 *
 * @module ui/component/header
 * @category Components
 */
import header from "@smartedge/em-fe/ui/html/header";
import img from "@smartedge/em-fe/ui/html/img";
import figure from "@smartedge/em-fe/ui/html/figure";
import a from "@smartedge/em-fe/ui/html/a";
import span from "@smartedge/em-fe/ui/html/span";
import h1 from "@smartedge/em-fe/ui/html/h1";
import div from "@smartedge/em-fe/ui/html/div";

const logo = () => figure(".logo", [
  a(
    img("", "/media/logo-smartedge-padded.svg", "SmartEdge"),
    "/",
    "",
    { attrs: { tabindex: "-1" } },
  ),
]);

// eslint-disable-next-line import/prefer-default-export
export const standardHeader = ({ state }) => header(
  ".standard",
  [
    logo(),
    span([
      h1(state.title),
    ], ".logo-title"),
    div(".controls", [
      ...(state.controls?.() || []),
    ]),
  ],
);
