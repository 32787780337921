/**
 * Automation deployment form page
 *
 * @module ui/page/deployment
 * @category Pages
 */
import standardLayout from "ui/page/layout/standard";
import form from "@smartedge/em-fe/ui/component/form-managed";
import stepper from "ui/component/stepper";
import div from "@smartedge/em-fe/ui/html/div";
import view from "@smartedge/em-fe/ui/view";
import formManagedView from "@smartedge/em-fe/ui/view/form-managed";
import { clearFormState, getCurrentFormState, saveFormState } from "util/cache";
import contactButton from "ui/component/contact-button";
import { getAccessToken } from "util/params";
import { redirectToReviewPage, validateTokenAndRedirectIfBad } from "util/navigation";
import { createOrder } from "api/order";
import { getNotification } from "@smartedge/em-fe/ui/view/notification-view";
import { notificationMessageTypes } from "@smartedge/em-fe/model/notification/constants";
import contactForm, { CONTACT_FORM_ID } from "./contact-form";
import companyDetailsForm, { COMPANY_DETAILS_FORM_ID } from "./company-details-form";
import tierForm, { TIER_FORM_ID } from "./tier-form";
import deploymentInfoForm, { DEPLOYMENT_INFO_FORM_ID } from "./deployment-info-form";
import contentQuestionsForm, { CONTENT_QUESTION_FORM_ID } from "./content-questions-form";
import { formTypes } from "./constants";
import { formActivator, formViewMap } from "./common";

let contactFormView;
let companyDetailsFormView;
let tierFormView;
let deploymentInfoFormView;
let contentQuestionsFormView;
let mainView;

let activateForm;

const onContactFormSubmit = (values) => {
  saveFormState(formTypes.CONTACT_FORM, formTypes.COMPANY_DETAILS_FORM, values);
  activateForm(formTypes.COMPANY_DETAILS_FORM);
};

const onCompanyDetailsBack = () => {
  activateForm(formTypes.CONTACT_FORM);
};

const onCompanyDetailsSubmit = (values) => {
  saveFormState(formTypes.COMPANY_DETAILS_FORM, formTypes.TIER_FORM, values);
  activateForm(formTypes.TIER_FORM);
};

const onTierFormBack = () => {
  activateForm(formTypes.COMPANY_DETAILS_FORM);
};

const onTierFormSubmit = (values) => {
  saveFormState(formTypes.TIER_FORM, formTypes.DEPLOYMENT_INFO_FORM, values);
  activateForm(formTypes.DEPLOYMENT_INFO_FORM);
};

const onDeploymentInfoFormBack = () => {
  activateForm(formTypes.TIER_FORM);
};

const onDeploymentInfoFormSubmit = (values) => {
  saveFormState(formTypes.DEPLOYMENT_INFO_FORM, formTypes.QUESTIONS_FORM, values);
  activateForm(formTypes.QUESTIONS_FORM);
};

const onContentQuestionsFormBack = () => {
  activateForm(formTypes.DEPLOYMENT_INFO_FORM);
};

const onContentQuestionsFormSubmit = async (values) => {
  saveFormState(formTypes.QUESTIONS_FORM, formTypes.QUESTIONS_FORM, values);
  const formState = getCurrentFormState();
  const partial = {
    customer: {
      contact: formState[formTypes.CONTACT_FORM],
      company: formState[formTypes.COMPANY_DETAILS_FORM],
    },
    system: formState[formTypes.TIER_FORM],
    deployment: formState[formTypes.DEPLOYMENT_INFO_FORM],
    content: formState[formTypes.QUESTIONS_FORM],
    inviteToken: getAccessToken(),
  };
  try {
    await createOrder(partial);
    clearFormState();
    redirectToReviewPage();
  } catch (e) {
    getNotification().post({
      title: "Error",
      text: "Sorry. The order hasn't been saved. The link was already used or no longer valid.",
      type: notificationMessageTypes.FAIL,
    });
  }
};

const mainLayout = (self) => div(".main-container", [
  stepper({
    steps: [
      {
        layout: div(".layout-container", [
          div(".column-layout", [
            div(".pane.pane-1", [
              form(CONTACT_FORM_ID),
            ]),
            div(".with-divider", div(".divider")),
            div(".pane.pane-2", [
              form(COMPANY_DETAILS_FORM_ID),
            ]),
          ]),
        ]),
      },
      {
        layout: div(".layout-container", [
          div(".column-layout", [
            div(".pane.pane-3", [
              form(TIER_FORM_ID),
            ]),
            div(".with-divider", div(".divider")),
            div(".pane.pane-4", [
              form(DEPLOYMENT_INFO_FORM_ID),
            ]),
          ]),
        ]),
      },
      {
        layout: div(".layout-container", [
          div(".column-layout", [
            div(".pane.pane-5", [
              form(CONTENT_QUESTION_FORM_ID),
            ]),
          ]),
        ]),
      },
    ],
    currentStep: self.state.currentStep,
  }),
]);

const doUpdate = (self) => {
  self.render();
  if (contactFormView) {
    contactFormView.rebind(CONTACT_FORM_ID);
    contactFormView.update(self.state);
  }
  if (companyDetailsFormView) {
    companyDetailsFormView.rebind(COMPANY_DETAILS_FORM_ID);
    companyDetailsFormView.update(self.state);
  }
  if (tierFormView) {
    tierFormView.rebind(TIER_FORM_ID);
    tierFormView.update(self.state);
  }
  if (deploymentInfoFormView) {
    deploymentInfoFormView.rebind(DEPLOYMENT_INFO_FORM_ID);
    deploymentInfoFormView.update(self.state);
  }
  if (contentQuestionsFormView) {
    contentQuestionsFormView.rebind(CONTENT_QUESTION_FORM_ID);
    contentQuestionsFormView.update(self.state);
  }
};

export default async function deployment(selector) {
  const title = "Automated Deployment";
  const { header, loading } = standardLayout(
    selector,
    [
      form("#body"),
    ],
    title,
  );
  loading.show();

  await validateTokenAndRedirectIfBad();

  const formState = getCurrentFormState();

  const state = {
    currentStep: 0,
    formState,
  };

  header.update({ title, controls: () => [contactButton()] });
  mainView = view.create(mainLayout, doUpdate)("#body", state);
  contactFormView = formManagedView(contactForm)(CONTACT_FORM_ID, {
    ...state,
    onSubmit: onContactFormSubmit,
  });
  formViewMap.set(formTypes.CONTACT_FORM, contactFormView);
  companyDetailsFormView = formManagedView(companyDetailsForm)(COMPANY_DETAILS_FORM_ID, {
    ...state,
    onBack: onCompanyDetailsBack,
    onSubmit: onCompanyDetailsSubmit,
  });
  formViewMap.set(formTypes.COMPANY_DETAILS_FORM, companyDetailsFormView);
  tierFormView = formManagedView(tierForm)(TIER_FORM_ID, {
    ...state,
    onBack: onTierFormBack,
    onSubmit: onTierFormSubmit,
  });
  formViewMap.set(formTypes.TIER_FORM, tierFormView);
  deploymentInfoFormView = formManagedView(deploymentInfoForm)(DEPLOYMENT_INFO_FORM_ID, {
    ...state,
    onBack: onDeploymentInfoFormBack,
    onSubmit: onDeploymentInfoFormSubmit,
  });
  formViewMap.set(formTypes.DEPLOYMENT_INFO_FORM, deploymentInfoFormView);
  contentQuestionsFormView = formManagedView(contentQuestionsForm)(
    CONTENT_QUESTION_FORM_ID,
    {
      ...state,
      onBack: onContentQuestionsFormBack,
      onSubmit: onContentQuestionsFormSubmit,
    },
  );
  formViewMap.set(formTypes.QUESTIONS_FORM, contentQuestionsFormView);

  activateForm = formActivator(mainView);
  const activeForm = state.formState?.activeForm;
  if (activeForm) {
    activateForm(activeForm);
  } else {
    activateForm(formTypes.CONTACT_FORM);
  }

  loading.hide();
}
