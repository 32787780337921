/**
 * Constants related to the model (should be divided by individual files if grows huge)
 *
 * @module model/constants
 * @category Model
 */

/**
 * Represents a collection of company types.
 *
 * @enum
 * @property {string} ADMINISTRATION_AND_BUSINESS_SUPPORT - Administration and Business Support.
 * @property {string} ARTS_ENTERTAINMENT - Arts and Entertainment.
 * @property {string} EDUCATION - Education.
 * @property {string} FINANCE_AND_INSURANCE - Finance and Insurance.
 * @property {string} HEALTHCARE - Healthcare.
 * @property {string} MEDIA - Media.
 * @property {string} OTHER_SERVICES - Other Services.
 * @property {string} TECHNOLOGY - Technology.
 */
export const companyTypes = Object.freeze({
  ADMINISTRATION_AND_BUSINESS_SUPPORT: "ADMINISTRATION_AND_BUSINESS_SUPPORT",
  ARTS_ENTERTAINMENT: "ARTS_ENTERTAINMENT",
  EDUCATION: "EDUCATION",
  FINANCE_AND_INSURANCE: "FINANCE_AND_INSURANCE",
  HEALTHCARE: "HEALTHCARE",
  MEDIA: "MEDIA",
  OTHER_SERVICES: "OTHER_SERVICES",
  TECHNOLOGY: "TECHNOLOGY",
});

/**
 * Represents a set of company types.
 *
 * @type {Set<companyTypes>}
 * @constant
 */
export const companyTypesSet = Object.freeze(
  new Set(Object.values(companyTypes)),
);

/**
 * A constant variable that holds the friendly names of different company types.
 *
 * @type {Map<companyTypes, string>}
 */
export const companyTypesFriendly = Object.freeze(new Map([
  [companyTypes.ADMINISTRATION_AND_BUSINESS_SUPPORT, "Administration / Business Support"],
  [companyTypes.ARTS_ENTERTAINMENT, "Arts / Entertainment"],
  [companyTypes.EDUCATION, "Education"],
  [companyTypes.FINANCE_AND_INSURANCE, "Finance / Insurance"],
  [companyTypes.HEALTHCARE, "Healthcare"],
  [companyTypes.MEDIA, "Media"],
  [companyTypes.OTHER_SERVICES, "Other Services"],
  [companyTypes.TECHNOLOGY, "Technology"],
]));

/**
 * Represents the types of medical specialities.
 *
 * @enum
 * @property {string} ANATOMICAL_PATHOLOGY - Anatomical Pathology speciality
 * @property {string} ANESTHESIOLOGY - Anesthesiology speciality
 * @property {string} CARDIOLOGY - Cardiology speciality
 * @property {string} CARDIOVASCULAR_THORACIC_SURGERY - Cardiovascular Thoracic Surgery speciality
 * @property {string} CLINICAL_IMMUNOLOGY_ALLERGY - Clinical Immunology Allergy speciality
 * @property {string} CRITICAL_CARE_MEDICINE - Critical Care Medicine speciality
 * @property {string} DERMATOLOGY - Dermatology speciality
 * @property {string} DIAGNOSTIC_RADIOLOGY - Diagnostic Radiology speciality
 * @property {string} EMERGENCY_MEDICINE - Emergency Medicine speciality
 * @property {string} ENDOCRINOLOGY_AND_METABOLISM - Endocrinology and Metabolism speciality
 * @property {string} FAMILY_MEDICINE - Family Medicine speciality
 * @property {string} GASTROENTEROLOGY - Gastroenterology speciality
 * @property {string} GENERAL_INTERNAL_MEDICINE - General Internal Medicine speciality
 * @property {string} GENERAL_SURGERY - General Surgery speciality
 * @property {string} GENERAL_CLINICAL_PATHOLOGY - General Clinical Pathology speciality
 * @property {string} GERIATRIC_MEDICINE - Geriatric Medicine speciality
 * @property {string} HEMATOLOGY - Hematology speciality
 * @property {string} MEDICAL_BIOCHEMISTRY - Medical Biochemistry speciality
 * @property {string} MEDICAL_GENETICS - Medical Genetics speciality
 * @property {string} MEDICAL_MICROBIOLOGY_AND_INFECTIOUS_DISEASES - Medical Microbiology
 * and Infectious Diseases speciality
 * @property {string} MEDICAL_ONCOLOGY - Medical Oncology speciality
 * @property {string} NEPHROLOGY - Nephrology speciality
 * @property {string} NEUROLOGY - Neurology speciality
 * @property {string} NEUROSURGERY - Neurosurgery speciality
 * @property {string} NUCLEAR_MEDICINE - Nuclear Medicine speciality
 * @property {string} OBSTETRICS_GYNECOLOGY - Obstetrics Gynecology speciality
 * @property {string} OCCUPATIONAL_MEDICINE - Occupational Medicine speciality
 * @property {string} OPHTHALMOLOGY - Ophthalmology speciality
 * @property {string} ORTHOPEDIC_SURGERY - Orthopedic Surgery speciality
 * @property {string} OTOLARYNGOLOGY - Otolaryngology speciality
 * @property {string} PEDIATRICS - Pediatrics speciality
 * @property {string} PHYSICAL_MEDICINE_AND_REHABILITATION - Physical Medicine and
 *                                                           Rehabilitation speciality
 * @property {string} PLASTIC_SURGERY - Plastic Surgery speciality
 * @property {string} PSYCHIATRY - Psychiatry speciality
 * @property {string} PUBLIC_HEALTH_AND_PREVENTIVE_MEDICINE - Public Health and
 *                                                            Preventive Medicine speciality
 * @property {string} PULMONOLOGY - Pulmonology speciality
 * @property {string} RADIATION_ONCOLOGY - Radiation Oncology speciality
 * @property {string} RESPIROLOGY - Respirology speciality
 * @property {string} RHEUMATOLOGY - Rheumatology speciality
 * @property {string} UROLOGY - Urology speciality
 */
export const specialityTypes = Object.freeze({
  ANATOMICAL_PATHOLOGY: "ANATOMICAL_PATHOLOGY",
  ANESTHESIOLOGY: "ANESTHESIOLOGY",
  CARDIOLOGY: "CARDIOLOGY",
  CARDIOVASCULAR_THORACIC_SURGERY: "CARDIOVASCULAR_THORACIC_SURGERY",
  CLINICAL_IMMUNOLOGY_ALLERGY: "CLINICAL_IMMUNOLOGY_ALLERGY",
  CRITICAL_CARE_MEDICINE: "CRITICAL_CARE_MEDICINE",
  DERMATOLOGY: "DERMATOLOGY",
  DIAGNOSTIC_RADIOLOGY: "DIAGNOSTIC_RADIOLOGY",
  EMERGENCY_MEDICINE: "EMERGENCY_MEDICINE",
  ENDOCRINOLOGY_AND_METABOLISM: "ENDOCRINOLOGY_AND_METABOLISM",
  FAMILY_MEDICINE: "FAMILY_MEDICINE",
  GASTROENTEROLOGY: "GASTROENTEROLOGY",
  GENERAL_INTERNAL_MEDICINE: "GENERAL_INTERNAL_MEDICINE",
  GENERAL_SURGERY: "GENERAL_SURGERY",
  GENERAL_CLINICAL_PATHOLOGY: "GENERAL_CLINICAL_PATHOLOGY",
  GERIATRIC_MEDICINE: "GERIATRIC_MEDICINE",
  HEMATOLOGY: "HEMATOLOGY",
  MEDICAL_BIOCHEMISTRY: "MEDICAL_BIOCHEMISTRY",
  MEDICAL_GENETICS: "MEDICAL_GENETICS",
  MEDICAL_MICROBIOLOGY_AND_INFECTIOUS_DISEASES: "MEDICAL_MICROBIOLOGY_AND_INFECTIOUS_DISEASES",
  MEDICAL_ONCOLOGY: "MEDICAL_ONCOLOGY",
  NEPHROLOGY: "NEPHROLOGY",
  NEUROLOGY: "NEUROLOGY",
  NEUROSURGERY: "NEUROSURGERY",
  NUCLEAR_MEDICINE: "NUCLEAR_MEDICINE",
  OBSTETRICS_GYNECOLOGY: "OBSTETRICS_GYNECOLOGY",
  OCCUPATIONAL_MEDICINE: "OCCUPATIONAL_MEDICINE",
  OPHTHALMOLOGY: "OPHTHALMOLOGY",
  ORTHOPEDIC_SURGERY: "ORTHOPEDIC_SURGERY",
  OTOLARYNGOLOGY: "OTOLARYNGOLOGY",
  PEDIATRICS: "PEDIATRICS",
  PHYSICAL_MEDICINE_AND_REHABILITATION: "PHYSICAL_MEDICINE_AND_REHABILITATION",
  PLASTIC_SURGERY: "PLASTIC_SURGERY",
  PSYCHIATRY: "PSYCHIATRY",
  PUBLIC_HEALTH_AND_PREVENTIVE_MEDICINE: "PUBLIC_HEALTH_AND_PREVENTIVE_MEDICINE",
  PULMONOLOGY: "PULMONOLOGY",
  RADIATION_ONCOLOGY: "RADIATION_ONCOLOGY",
  RESPIROLOGY: "RESPIROLOGY",
  RHEUMATOLOGY: "RHEUMATOLOGY",
  UROLOGY: "UROLOGY",
});

/**
 * Represents a set of speciality types.
 *
 * @type {Set<specialityTypes>}
 */
export const specialityTypesSet = Object.freeze(
  new Set(Object.values(specialityTypes)),
);

/**
 * The `specialityTypesFriendly` variable is a map that maps each speciality type
 * to its friendly name.
 *
 * @type {Map<specialityTypes, string>}
 */
export const specialityTypesFriendly = Object.freeze(new Map([
  [specialityTypes.ANATOMICAL_PATHOLOGY, "Anatomical Pathology"],
  [specialityTypes.ANESTHESIOLOGY, "Anesthesiology"],
  [specialityTypes.CARDIOLOGY, "Cardiology"],
  [specialityTypes.CARDIOVASCULAR_THORACIC_SURGERY, "Cardiovascular / Thoracic Surgery"],
  [specialityTypes.CLINICAL_IMMUNOLOGY_ALLERGY, "Clinical Immunology / Allergy"],
  [specialityTypes.CRITICAL_CARE_MEDICINE, "Critical Care Medicine"],
  [specialityTypes.DERMATOLOGY, "Dermatology"],
  [specialityTypes.DIAGNOSTIC_RADIOLOGY, "Diagnostic Radiology"],
  [specialityTypes.EMERGENCY_MEDICINE, "Emergency Medicine"],
  [specialityTypes.ENDOCRINOLOGY_AND_METABOLISM, "Endocrinology and Metabolism"],
  [specialityTypes.FAMILY_MEDICINE, "Family Medicine"],
  [specialityTypes.GASTROENTEROLOGY, "Gastroenterology"],
  [specialityTypes.GENERAL_INTERNAL_MEDICINE, "General Internal Medicine"],
  [specialityTypes.GENERAL_SURGERY, "General Surgery"],
  [specialityTypes.GENERAL_CLINICAL_PATHOLOGY, "General / Clinical Pathology"],
  [specialityTypes.GERIATRIC_MEDICINE, "Geriatric Medicine"],
  [specialityTypes.HEMATOLOGY, "Hematology"],
  [specialityTypes.MEDICAL_BIOCHEMISTRY, "Medical Biochemistry"],
  [specialityTypes.MEDICAL_GENETICS, "Medical Genetics"],
  [specialityTypes.MEDICAL_MICROBIOLOGY_AND_INFECTIOUS_DISEASES, "Medical Microbiology and Infectious Diseases"],
  [specialityTypes.MEDICAL_ONCOLOGY, "Medical Oncology"],
  [specialityTypes.NEPHROLOGY, "Nephrology"],
  [specialityTypes.NEUROLOGY, "Neurology"],
  [specialityTypes.NEUROSURGERY, "Neurosurgery"],
  [specialityTypes.NUCLEAR_MEDICINE, "Nuclear Medicine"],
  [specialityTypes.OBSTETRICS_GYNECOLOGY, "Obstetrics / Gynecology"],
  [specialityTypes.OCCUPATIONAL_MEDICINE, "Occupational Medicine"],
  [specialityTypes.OPHTHALMOLOGY, "Ophthalmology"],
  [specialityTypes.ORTHOPEDIC_SURGERY, "Orthopedic Surgery"],
  [specialityTypes.OTOLARYNGOLOGY, "Otolaryngology"],
  [specialityTypes.PEDIATRICS, "Pediatrics"],
  [specialityTypes.PHYSICAL_MEDICINE_AND_REHABILITATION, "Physical Medicine and Rehabilitation (PM & R)"],
  [specialityTypes.PLASTIC_SURGERY, "Plastic Surgery"],
  [specialityTypes.PSYCHIATRY, "Psychiatry"],
  [specialityTypes.PUBLIC_HEALTH_AND_PREVENTIVE_MEDICINE, "Public Health and Preventive Medicine (PhPm)"],
  [specialityTypes.PULMONOLOGY, "Pulmonology"],
  [specialityTypes.RADIATION_ONCOLOGY, "Radiation Oncology"],
  [specialityTypes.RESPIROLOGY, "Respirology"],
  [specialityTypes.RHEUMATOLOGY, "Rheumatology"],
  [specialityTypes.UROLOGY, "Urology"],
]));

/**
 * An enumeration representing different company sizes.
 *
 * @enum
 * @readonly
 * @property {string} ONE_FIFTY - Represents a company size of 1-50 employees.
 * @property {string} FIFTY_TWO_HUNDRED - Represents a company size of 51-200 employees.
 * @property {string} TWO_FIVE_HUNDRED - Represents a company size of 201-500 employees.
 * @property {string} FIVE_HUNDRED_PLUS - Represents a company size of 500+ employees.
 */
export const companySizes = Object.freeze({
  ONE_FIFTY: "ONE_FIFTY",
  FIFTY_TWO_HUNDRED: "FIFTY_TWO_HUNDRED",
  TWO_FIVE_HUNDRED: "TWO_FIVE_HUNDRED",
  FIVE_HUNDRED_PLUS: "FIVE_HUNDREDS_PLUS",
});

/**
 * Represents a frozen set of company sizes.
 *
 * @type {Set<companySizes>}
 */
export const companySizesSet = Object.freeze(
  new Set(Object.values(companySizes)),
);

/**
 * Represents the mapping of company sizes to their corresponding friendly labels.
 *
 * @type {Map<companySizes, string>}
 */
export const companySizesFriendly = Object.freeze(new Map([
  [companySizes.ONE_FIFTY, "1-50"],
  [companySizes.FIFTY_TWO_HUNDRED, "50-200"],
  [companySizes.TWO_FIVE_HUNDRED, "200-500"],
  [companySizes.FIVE_HUNDRED_PLUS, "500+"],
]));

/**
 * Variable representing the content amounts.
 *
 * @enum
 * @readonly
 * @property {string} ONE_TEN - Represents the content amount from 1 to 10.
 * @property {string} TEN_TWENTY_FIVE - Represents the content amount from 10 to 25.
 * @property {string} TWENTY_FIVE_FIFTY - Represents the content amount from 25 to 50.
 * @property {string} FIFTY_PLUS - Represents the content amount above 50.
 */
export const contentAmounts = Object.freeze({
  ONE_TEN: "ONE_TEN",
  TEN_TWENTY_FIVE: "TEN_TWENTY_FIVE",
  TWENTY_FIVE_FIFTY: "TWENTY_FIVE_FIFTY",
  FIFTY_PLUS: "FIFTY_PLUS",
});

/**
 * Represents a frozen set of content amounts.
 *
 * @type {Set<contentAmountsSet>}
 */
export const contentAmountsSet = Object.freeze(
  new Set(Object.values(contentAmounts)),
);

/**
 * Represents the mapping of content amounts to their corresponding friendly labels.
 *
 * @type {Map<contentAmounts, string>}
 */
export const contentAmountsFriendly = Object.freeze(new Map([
  [contentAmounts.ONE_TEN, "1-10"],
  [contentAmounts.TEN_TWENTY_FIVE, "10-25"],
  [contentAmounts.TWENTY_FIVE_FIFTY, "25-50"],
  [contentAmounts.FIFTY_PLUS, "50+"],
]));

/**
 * The intended use cases
 *
 * @enum
 * @property {string} CONTENT_STREAMING - For content streaming applications.
 * @property {string} WEBSITE_BUILDER - For website builder applications.
 * @property {string} EDUCATION - For education applications.
 * @property {string} OUTREACH - For outreach applications.
 * @property {string} PORTFOLIO - For portfolio applications.
 * @property {string} DEMO_SYSTEM - For demo system applications.
 */
export const intendedUseCase = Object.freeze({
  CONTENT_STREAMING: "CONTENT_STREAMING",
  WEBSITE_BUILDER: "WEBSITE_BUILDER",
  EDUCATION: "EDUCATION",
  OUTREACH: "OUTREACH",
  PORTFOLIO: "PORTFOLIO",
  DEMO_SYSTEM: "DEMO_SYSTEM",
});

/**
 * Represents a frozen set of intended use cases.
 *
 * @name intendedUseCaseSet
 * @type {Set<intendedUseCase>}
 */
export const intendedUseCaseSet = Object.freeze(
  new Set(Object.values(intendedUseCase)),
);

/**
 * A map of intended use cases to their friendly names.
 *
 * @type {Map<intendedUseCase, string>}
 */
export const intendedUseCaseFriendly = Object.freeze(new Map([
  [intendedUseCase.CONTENT_STREAMING, "Content Streaming"],
  [intendedUseCase.WEBSITE_BUILDER, "Website Builder"],
  [intendedUseCase.EDUCATION, "Education"],
  [intendedUseCase.OUTREACH, "Outreach"],
  [intendedUseCase.PORTFOLIO, "Portfolio"],
  [intendedUseCase.DEMO_SYSTEM, "Demo System"],
]));

/**
 * Represents the types of tiers.
 *
 * @enum
 * @property {string} TIER_1 - Represents tier 1.
 * @property {string} TIER_2 - Represents tier 2.
 * @property {string} TIER_3 - Represents tier 3.
 */
export const tierTypes = Object.freeze({
  TIER_1: "TIER_1",
  TIER_2: "TIER_2",
  TIER_3: "TIER_3",
});

/**
 * Represents a set of tier types.
 *
 * @type {Set<tierTypes>}
 */
export const tierTypesSet = Object.freeze(
  new Set(Object.values(tierTypes)),
);

/**
 * A variable representing a map of friendly tier types.
 *
 * @type {Map<tierTypes, string>}
 */
export const tierTypesFriendly = Object.freeze(new Map([
  [tierTypes.TIER_1, "Tier 1"],
  [tierTypes.TIER_2, "Tier 2"],
  [tierTypes.TIER_3, "Tier 3"],
]));

/**
 * Represents the available types of domains.
 *
 * @enum
 * @property {string} SMARTEDGE - Represents the Smart Edge domain type.
 * @property {string} CUSTOM - Represents a custom domain type.
 */
export const domainTypes = Object.freeze({
  SMARTEDGE: "SMART_EDGE",
  CUSTOM: "CUSTOM",
});

/**
 * Represents a frozen set of domain types.
 *
 * @constant {Set<domainTypes>} domainTypesSet
 * @readonly
 */
export const domainTypesSet = Object.freeze(
  new Set(Object.values(domainTypes)),
);

/**
 * A constant variable that represents the friendly names for different domain types.
 *
 * @type {Map<domainTypes, string>}
 */
export const domainTypesFriendly = Object.freeze(new Map([
  [domainTypes.SMARTEDGE, "SmartEdge Domain"],
  [domainTypes.CUSTOM, "Custom Domain"],
]));
